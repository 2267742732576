import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { login } from '../../util/utils'
import _ from 'lodash'
import style from './index.module.scss'
function Footer() {
  const utoken = localStorage.getItem('utoken')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  let [tabbar, setTabbar] = useState([
    {
      name: '微云仓',
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/doushenghuo/home_off.png',
      active_icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/doushenghuo/home_on.png',
      selected: !0,
      path: '/'
    },
    //{
    //  name: '企业列表',
    //  icon: icon32,
    //  active_icon: icon31,
    //  selected: !0,
    //  path: '/enterpriseList'
    //},
    // {
    //   name:'种草社区',
    //   icon: icon19,
    //   active_icon: icon20,
    //   selected: !0,
    //   path:'/community'
    // },
    //{
    //  name: '兑好礼',
    //  icon: icon17,
    //  active_icon: icon22,
    //  selected: !0,
    //  path: '/pointsMall'
    //},
    {
      name: '个人中心',
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/doushenghuo/my_off.png',
      active_icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/doushenghuo/my_on.png',
      selected: !1,
      path: '/my'
    }
  ])
  let go = (index) => {
    if (!utoken) return login()
    let data = _.cloneDeep(tabbar)
    if (data[index].path) {
      data.map((item) => (item.selected = !1))
      data[index].selected = !0
      setTabbar(data)
      navigate(data[index].path)
    }
  }
  useEffect(() => {
    let data = _.cloneDeep(tabbar)
    data.map((item) => {
      if (item.path == pathname) {
        item.selected = !0
      } else {
        item.selected = !1
      }
    })
    setTabbar(data)
  }, [pathname])
  return (
    <>
      <div className={style.footer}>
        {tabbar.map((item, index) => {
          return (
            <div style={{ color: item.selected ? '#08DFB4' : '' }} key={index} onClick={() => go(index)}>
              <img src={item.selected ? item.active_icon : item.icon} />
              <span>{item.name}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Footer
