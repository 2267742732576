import style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
function ActivationApply() {
  const navigate = useNavigate()
  return (
    <div className={style.back} style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/weiyuncang/H5/img2.png)' }}>
      <div onClick={() => navigate('/activation')}>立即申请</div>
    </div>
  )
}

export default ActivationApply
