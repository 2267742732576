import style from './index.module.scss'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../../util/axios'
import { Image, Popup, TextArea, Toast } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
function My() {
  const navigate = useNavigate()
  let [info, setInfo] = useState({
    user_info: {},
    client_info: {}
  })
  let [state, setState] = useState({
    content: '',
    showFeedback: !1,
    showCustomerServiceCode: !1
  })
  let func = [
    {
      icon: require('../../image/my_icon2.png'),
      name: '我的订单'
    },
    {
      icon: require('../../image/my_icon3.png'),
      name: '意见反馈'
    },
    {
      icon: require('../../image/my_icon4.png'),
      name: '联系客服'
    },
    {
      icon: require('../../image/my_icon5.png'),
      name: '新手教程'
    },
    //{
    //    icon: require("../../image/my_icon7.png"),
    //    name: '积分订单'
    //},
    {
      icon: require('../../image/my_icon9.png'),
      name: '收货地址'
    },
    //{
    //    icon: require("../../image/my_icon8.png"),
    //    name: '企业订单'
    //},
    {
      icon: require('../../image/my_icon10.png'),
      name: '购物车'
    }
  ]
  let getInfo = () => {
    axios.post('/JuTwitter/User/index').then((res) => {
      if (res.code === 0) {
        setInfo(res.result)
      }
    })
  }
  let go = (index) => {
    if (index == 0) {
      navigate('/order')
    }
    if (index == 1) {
      setState({
        ...state,
        showFeedback: !0
      })
    }
    if (index == 2) {
      setState({
        ...state,
        showCustomerServiceCode: !0
      })
    }
    if (index == 4) {
      navigate('/integalOrder')
    }
    if (index == 5) {
      navigate('/myAddress')
    }
    if (index == 6) {
      navigate('/enterpriseOrder')
    }
    if (index == 7) {
      navigate('/cart')
    }
  }
  let submitFeedback = () => {
    if (!state.content) return Toast.show({ content: '请输入意见反馈' })
    axios
      .post('/JuTwitter/User/feedback', {
        content: state.content
      })
      .then((res) => {
        if (res.code == 0) {
          Toast.show({
            content: '提交成功',
            icon: 'success'
          })
          setState({
            ...state,
            content: '',
            showFeedback: !1
          })
        } else {
          Toast.show({
            content: res.msg
          })
        }
      })
  }
  useEffect(() => {
    getInfo()
  }, [])
  return (
    <div className={style.content}>
      <div className={style.userInfo}>
        <div>
          <Image src={info.user_info.headimgurl} />
          <span>{info.user_info.nickname}</span>
        </div>
        <div className={style.release} onClick={() => navigate('/publishingCenter')}>
          发布中心
        </div>
      </div>
      <div
        className={style.money}
        style={{ backgroundImage: 'url(' + require('../../image/back2.png') + ')' }}
        onClick={() => navigate('/myWallet', { state: { cash: info.user_info.cash, minimum_withdrawal_amount: info.client_info.minimum_withdrawal_amount } })}
      >
        <div className={style.withdrawal_money}>
          <div>
            <Image src={require('../../image/my_icon1.png')} />
            <span>可提现余额（元）</span>
          </div>
          <div>
            <span>￥</span>
            <span>{info.user_info.cash}</span>
          </div>
        </div>
        <div className={style.right}>
          <RightOutline color="#fff" fontSize={16} />
        </div>
      </div>
      <div className={style.func}>
        <div className={style.title}>常用功能</div>
        <div className={style.list}>
          {func.map((item, index) => {
            return (
              <div className={style.item} key={index} onClick={() => go(index)}>
                <Image src={item.icon} />
                <span>{item.name}</span>
              </div>
            )
          })}
        </div>
      </div>
      <Popup
        visible={state.showFeedback}
        showCloseButton
        closeOnMaskClick
        onClose={() => {
          setState({
            ...state,
            showFeedback: !1
          })
        }}
      >
        <div className={style.feedback}>
          <div className={style.title}>意见反馈</div>
          <div className={style.put}>
            <TextArea
              placeholder="请输入您宝贵的建议"
              autoSize={{ minRows: 10, maxRows: 100 }}
              value={state.content}
              onChange={(val) => {
                setState({
                  ...state,
                  content: val
                })
              }}
            />
          </div>
          <div className={style.btn} onClick={() => submitFeedback()}>
            提交
          </div>
        </div>
      </Popup>
      <Popup
        visible={state.showCustomerServiceCode}
        showCloseButton
        closeOnMaskClick
        onClose={() => {
          setState({
            ...state,
            showCustomerServiceCode: !1
          })
        }}
      >
        <div className={style.customerServiceCode}>
          <div className={style.title}>联系客服</div>
          <div>企业微信扫码，直接联系客服</div>
          <Image src={info.client_info.wechat_client_img} />
        </div>
      </Popup>
    </div>
  )
}

export default My
