import React, { useState, useEffect } from 'react'
import axios from '../../util/axios'
import { wxConfig, handleLocationPath } from '../../util/utils'
import { useDispatch } from 'react-redux'
import Template2 from './components/template2'
import Loading from './components/loading'
import Pay from '../pay'
import DrawLottery from '../../components/drawLottery'
function Index () {
    const dispatch = useDispatch()
    const { sn, wifi_id } = handleLocationPath()
    const [shopInfo, setShopInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    let utoken = localStorage.getItem('utoken')
    let getShopInfo = () => {
        axios
            .post('/JuTwitter/Page/index', {
                wifi_id: wifi_id
            })
            .then((res) => {
                if (res.code === 0) {
                    let result = res.result
                    let wifiId = sessionStorage.getItem('wifi_id')
                    let shop_vr_banners = result.shop_info.shop_vr_banners
                    if (result.shop_info.style_type == 1 || result.shop_info.style_type > 2) {
                        if (wifiId) {
                            result.wifi_appid.path = `${result.wifi_appid.path}.html?sn=${localStorage.getItem('sn')}&wifi_id=${wifiId}`
                            result.scan_code_appid.path = `${result.scan_code_appid.path}.html?sn=${localStorage.getItem('sn')}&wifi_id=${wifiId}`
                        } else {
                            result.wifi_appid.path = `${result.wifi_appid.path}.html?sn=${localStorage.getItem('sn')}`
                            result.scan_code_appid.path = `${result.scan_code_appid.path}.html?sn=${localStorage.getItem('sn')}`
                        }
                        wxConfig()
                    } else {
                        dispatch({ type: 'setShowTabbar', data: !0 })
                    }
                    shop_vr_banners.map(async (item) => {
                        if (item.moded) {
                            await axios.post('/JuTwitter/Page/' + item.moded).then((res) => {
                                if (res.code == 0) {
                                    if (item.changetype == 2) {
                                        item.original_id = res.result.original_id
                                        item.page_path = res.result.we_app_info.page_path
                                    } else {
                                        item.link = res.result
                                    }
                                }
                            })
                        }
                    })
                    axios.post('/JuTwitter/Wifi/index', { wifi_id: wifiId })
                    setShopInfo(result)
                }
            })
    }
    const initData = (data) => {
        console.log(data, 'init');
        setIsLoading(false)
    }
    useEffect(() => {
        let equipment = process.env.NODE_ENV == 'production' ? 'micromessenger' : null
        let browser = navigator.userAgent.toLowerCase()
        if (sn) localStorage.setItem('sn', sn)
        if (wifi_id) sessionStorage.setItem('wifi_id', wifi_id)
        if (browser.match(/MicroMessenger/i) == equipment) {
            if (utoken) getShopInfo()
        } else {
            axios
                .post('/WxShop/WechatUser/getAliUtoken', {
                    code: localStorage.getItem('sn')
                })
                .then((res) => {
                    setIsLoading(false)
                    if (res.code == 0) {
                        localStorage.setItem('utoken', res.result.utoken)
                        setShopInfo({
                            shop_info: {
                                style_type: 3
                            }
                        })
                        dispatch({ type: 'setShowTabbar', data: !0 })
                    }
                })
        }
    }, [])
    return (
        <>
            {isLoading ? (
                <Loading />
            ) : null}
            <>
                {shopInfo?.shop_info?.style_type == 3 ? <Pay /> : null}
                {shopInfo && shopInfo.shop_info && shopInfo.shop_info.style_type != 3 ? <Template2 info={shopInfo} initData={initData} /> : null}
                {!sn && shopInfo.shop_info && Math.floor(Math.random() * 10 + 1) == 1 ? <DrawLottery /> : null}
            </>
        </>
    )
}

export default Index
