import style from './index.module.scss'
function index() {
  return (
    <div className={style.loading}>
      <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/weiyuncang/H5/loading.gif" alt="" />
    </div>
  )
}

export default index
